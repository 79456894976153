<template>
  <div style="text-align: left">基地信息</div>

  <el-form
    :model="addBaseForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item label="基地名称" size="small" prop="title" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addBaseForm.baseName"
          clearable
          placeholder="请输入基地名称"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="基地地址"
      size="small"
      prop="goodClass"
      style="width: 50%"
    >
      <div style="display: flex">
         <el-input
          v-model="addBaseForm.baseAddr"
          clearable
          placeholder="请输入基地地址"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="种植性质"
      size="small"
      prop="basePrice"
      style="width: 50%"
    >
      <div class="displayflex" style="align-items: center;height: 32px;">
          <el-input
          v-model="addBaseForm.plantType"
          clearable
          placeholder="请输入种植性质"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
      <el-form-item
      label="种植规模"
      size="small"
      prop="basePrice"
      style="width: 50%"
    >
      <div class="displayflex" style="align-items: center;height: 32px;">
          <el-input
          v-model="addBaseForm.plantScale"
          clearable
          placeholder="请输入种植规模"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="管理方式"
      size="small"
      prop="basePrice"
      style="width: 50%"
    >
      <div class="displayflex">
        <el-input
          v-model="addBaseForm.management"
          clearable
          placeholder="请输入管理方式"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="追溯地址"
      size="small"
      prop="salePrice"
      style="width: 50%"
    >
      <div class="displayflex">
         <el-input
          v-model="addBaseForm.traceUrl"
          clearable
          placeholder="请输入追溯地址"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="基地图片"
      prop="coursePic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
        :action="UploadUrl"
        :headers="{
          authorization: Token
        }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="true"
        :before-upload="handlebeforupload"
        accept=""

        :file-list="basefilelist"
        :on-success="filechange"
        :limit="1"
        :on-exceed="handleexceed"
        ref="uploader"
      >
      <!--         :before-upload="handlebeforupload" -->
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file, fileList)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比11：8、图片大小200k为宜，只可设置一张图片,单张图片不可超过2M</span
      >
    </el-form-item>
    <el-form-item label="课程详情" style="width: 80%">
      <vue-ueditor-wrap
        v-model="addBaseForm.description"
        :config="editorConfig"
        editor-id="courseInfo"
      ></vue-ueditor-wrap>
    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      addBaseForm: {
        baseName: '',
        baseAddr: '',
        plantType: '',
        management: '',
        plantScale: '',
        description: '',
        traceUrl: '',
        place: 1,
        adopted: true,
        coverUrl: '',
      },
      rules: {
        baseName: [
          { required: true, message: '请输入基地名称！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        baseAddr: [
          { required: true, message: '请输入基地地址！', trigger: 'blur' }
        ],
        plantType: [
          { required: true, message: '请输入种植性质！', trigger: 'blur' }
        ],
        management: [
          { required: true, message: '请输入管理方式！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        plantScale: [
          { required: true, message: '请输入种植规模！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        traceUrl: [
          { required: true, message: '请输入追溯地址！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      content: '',
      activeName: 'first',
      professionOption: [],
      basefilelist: [],
      corsepicTemArr: [],
      props: {
        value: 'id',
        label: 'name'
      },
    }
  },
  methods: {
        handlebeforupload(file){
      return new Promise (async(resolve, reject)=>{
          const isLt2M = file.size / 1048576  < 2;
          if(!isLt2M && file.type.indexOf('image')!==-1){
              this.$message.error('上传图片请小于2M')
              reject()
          }else{
            resolve()
          }
      })
    },
    handleexceed() {
      this.$message.error('仅限上传1个媒体文件！')
    },
    handleRemove(file) {
        this.addBaseForm.coverUrl = ''
        this.basefilelist = []
    },
    async onsubmit() {
      console.log(this.addBaseForm)
      console.log(Array.isArray(this.corsepicTemArr))
      if (this.addBaseForm.coverUrl.length === 0 || this.addBaseForm.coverUrl.trim() === '') {
        this.$message.error('请上传图片！')
        return
      }
      const data = {}
      if (this.$route.params.isadd === 'true') {
          
      } else {
      
      }
      const res = await this.$http.post('/nktdec/baseinfo/baseinfos', this.addBaseForm)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('编辑基地信息成功！')
        this.$router.push('/basemange')
      }
    },
    async getBaseData(id) {
        const res = await this.$http.get('/nktdec/baseinfo/baseinfo/'+id)
        console.log(res)
        this.addBaseForm = res.data
        this.basefilelist.push({
            url: this.imgBaseUrl + this.addBaseForm.coverUrl
        })
    },
    async filechange(res,file, fileList) {
      console.log(res)
      // const isLt2M = file.size / 1048576  < 2;
      // if(!isLt2M && file.raw.type.indexOf('image')!==-1){
      //     this.$message.error('上传图片请小于2M')
      //      console.log(this.$refs.uploader)
      //     this.$refs.uploader.abort()
      //     this.$refs.uploader.clearFiles()
         
      //       // return false
      // }
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      // console.log(res)
      // this.basefilelist = fileList
      this.basefilelist.push({
        url: this.imgBaseUrl + res.result
      })
      this.addBaseForm.coverUrl = res.result
    },
  },
  computed: {
    editorConfig() {
      return this.$store.state.editorConfig
    },
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    BaseInfo() {
      return this.$store.state.BaseInfo
    },
    Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '基地管理',
        index: '/basemange'
      },
      {
        name: '基地列表',
        index: '/baselist'
      }, {
        name: '基地信息',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
     if (this.$route.params.id!=='add') {
        this.getBaseData(this.$route.params.id)
      }
    },
}
</script>
<style scoped>
</style>
