<template>
  <div style="text-align: left">基地列表</div>
  <div style="display: flex; justify-content: flex-start;margin-top:15px">
    <el-button type="primary" size="mini" @click="addbase">创建基地</el-button>
     <el-button
      type="primary"
      style="display: flex"
      size="mini"
      @click="swiperplace"
      >{{ isSortable ? "完成" : "排序" }}</el-button
    >
  </div>
  <el-table :data="tableData" stripe style="width: 100%; margin-top: 30px" row-key="id">
    <el-table-column type="index"></el-table-column>
    <el-table-column prop="coverUrl" label="基地图片" width="180">
      <template #default="scope">
        <img
          v-if="scope.row.coverUrl"
          :src="imgBaseUrl + scope.row.coverUrl"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column prop="baseName" label="基地名称" width="180">
    </el-table-column>
    <el-table-column prop="baseAddr" label="基地地址"> </el-table-column>
    <el-table-column prop="plantType" label="种植性质"> </el-table-column>
    <el-table-column prop="plantScale" label="种植规模"> </el-table-column>
    <el-table-column prop="management" label="管理方式">
    </el-table-column>
    <el-table-column prop="traceUrl" label="追溯地址"> </el-table-column>
    <el-table-column prop="adopted" label="是否展示">
      <template #default="scope">
        <el-tag v-if="scope.row.adopted === true" type="success">展示</el-tag>
        <el-tag v-else-if="scope.row.adopted === false" type="info"
          >隐藏</el-tag
        >
      </template>  
    </el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
        <div
          style="display: flex; justify-content: flex-start; flex-wrap: wrap"
        >
         <el-button
            type="text"
            v-if="!scope.row.adopted"
            @click="showbase(scope.row)"
            style="margin-right: 10px"
            >展示</el-button
          >
          <el-button
            type="text"
            v-else
            @click="showbase(scope.row)"
            style="margin-right: 10px"
            >隐藏</el-button
          >
          <el-button
            type="text"
            @click="editBase(scope.row)"
            style="margin-right: 10px"
            >编辑</el-button
          >
          <el-button
            type="text"
            style="margin-left: 0px !important"
            @click="deleteBase(scope.row)"
            >删除</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
  <!--  分页组件 -->
    <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10,15,20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
import Sortable from 'sortablejs'

export default {
  data() {
    return {
      tableData: [
      ],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      isSortable: false,
    }
  },
  created(){
     const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '基地管理',
        index: '/basemange'
      },
      {
        name: '基地列表',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getTabData(this.BaseSeachForm)
  },
  methods: {
    async swiperplace() {
      this.isSortable = !this.isSortable
      console.log(this.tableData)
      if (this.isSortable) {
        var _this = this
        this.sortable = new Sortable(document.querySelector('.el-table__body-wrapper tbody'), {
          animation: 150,
          ghostClass: 'blue-background-class',
          onEnd({ newIndex, oldIndex }) {
            const currRow = _this.tableData.splice(oldIndex, 1)[0]
            _this.tableData.splice(newIndex, 0, currRow)
            console.log(_this.tableData)
          }
        })
      } else {
        this.tableData.forEach(async (item, index) => {
          item.place = index + 1
          const res = await this.$http.post('/nktdec/baseinfo/baseinfos', item)
          console.log(res)
        })
        this.sortable.destroy()
      }
    },
    query(str,obj){
      if(obj.currentpage){
           str+= obj.currentpage+'?'
           for(let i in obj){
            if(i==='currentpage') {
                continue
            }else{
              if(i==='categoryId'){
                str+='categoryId='+ obj[i][1]
                continue
              }
              if(obj[i]!==''){
              str+='&'+i+'='+ obj[i]
            }
            }
         }
      }else{
        str+='1'
      }
      return str 
    },
    async getTabData(obj) {
      console.log(obj)
      var str = this.query('',obj)
      const res = await this.$http.get('/nktdec/baseinfo/baseinfos/' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = obj.currentpage - 0
      this.$store.commit('setBaseSeachForm',{...this.BaseSeachForm , currentpage : obj.currentpage , pageSize: '20'})
    },
    async showbase(row) {
      row.adopted = !row.adopted
      const res = await this.$http.post('/nktdec/baseinfo/baseinfos', row)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('编辑基地信息成功！')
      }
    },
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/baseinfo/baseinfos/1' + '?pageSize=' + val)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setBaseSeachForm',{...this.BaseSeachForm , currentpage : '1' , pageSize: val}) 
    },
    async handleCurrentChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/baseinfo/baseinfos/' + val+'?pageSize=' + this.pageSize)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = val
      this.$store.commit('setBaseSeachForm',{...this.BaseSeachForm , currentpage : val , pageSize: this.pageSize })
    },
    editBase(row) {
      window.sessionStorage.setItem('BaseInfo', JSON.stringify(row))
      this.$router.push('/changebase/'+row.id)
    },
    async deleteBase(row) {
      const res = await this.$http.delete('/nktdec/baseinfo/baseinfo/'+row.id)
      console.log(res)
      if(res.data.code==='1'){
        this.$message.success('基地已删除')
        this.getTabData(this.BaseSeachForm)
      }
    },
    addbase() {
      this.$router.push('/changebase/add')
    }
  },
  computed:{
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    BaseSeachForm() {
      return this.$store.state.BaseSeachForm
    },
  }
}
</script>
<style scoped>
.lineform {
  display: flex;
  justify-content: flex-start;
}
.expendform {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.expendform .el-form-item {
  width: 48%;
}
</style>
